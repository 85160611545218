/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

// Start Custom

// Start AOS
    AOS.init();

// Top Scroller

  // Glide Carousel
  var glider = $('#how-it-works').glide({
    type: 'slider',
paddings: '0',
    startAt: 1,
    autoplay: false,
    touchDistance: 2
  });

  // Glide Carousel
  var carousel = $('#carousel').glide({
    type: 'carousel',
paddings: '15%',
    startAt: 2,
    autoplay:8500,
    touchDistance: 2
  });
// Smooth Scroll
$('a.smooth').smoothScroll();
$('.smooth a').smoothScroll();
  // Match Height
  $(function() {
  	$('.quote-box').matchHeight();
  });
// Transit
$( ".grow" ).hover(
  function() {
    $( this ).stop().transition({ scale: 1.05 });
  }, function() {
    $( this ).stop().transition({ scale: 1 });
  }
);

$( ".logo-row img" ).hover(
  function() {
    $( this ).stop().transition({ opacity: 1,scale: 1.16 });
  }, function() {
    $( this ).stop().transition({ opacity: 0.9,scale: 1 });
  }
);


$( ".icon-list li" ).hover(
  function() {
    $( this).stop().find('img').transition({ opacity: 1,y:-5 });
  }, function() {
    $( this).stop().find('img').transition({ opacity: 0.6,y:0 });
  }
);


$( ".social-icons-footer img" ).hover(
  function() {
    $( this).stop().transition({ opacity: 0.6});
  }, function() {
    $( this).stop().transition({ opacity: 1});
  }
);

// Waypoints

// var waypoint = new Waypoint({
//   element: document.getElementById('logo-row'),
//   handler: function(direction) {
//     $( ".hero-device-wrap" ).transition({ opacity: 0, y: 250});
//   },
//   offset: 250
// });
// var waypoint2 = new Waypoint({
//   element: document.getElementById('logo-row'),
//   handler: function(direction) {
//     $( ".hero-device-wrap" ).transition({ opacity: 1, y: 0});
//   },
//   offset: 220
// });
//jQuery is required to run this code



// $('#header-block').affix({
//     offset: {
//       top: 100,
//       bottom: function () {
//         return (this.bottom = $('.footer').outerHeight(true))
//       }
//     }
//   });



 $(document).on('ready', function() {

   $('.gallery--projects__image a').featherlightGallery({
		previousIcon: '«',     /* Code that is used as previous icon */
		nextIcon: '»',         /* Code that is used as next icon */
		galleryFadeIn: 100,          /* fadeIn speed when slide is loaded */
		galleryFadeOut: 300          /* fadeOut speed before slide is loaded */
	});
   $(".testimonial-slider--single").slick({
     dots: true,
       arrows: false,
         adaptiveHeight: true,
     vertical: false,
     centerMode: false,
     slidesToShow: 1,
    pauseOnFocus: false,
    fade: true,
     pauseOnHover: true,
  autoplay: true,
  autoplaySpeed: 7000,
     slidesToScroll: 1,
     responsive: [
       {
         breakpoint: 767,
         settings: {
         pauseOnHover: false,
         }
       }
     ]
   });
     $(".gallery--projects").slick({
       dots: true,
         arrows: false,
           adaptiveHeight: true,
       vertical: false,
  infinite: true,
        speed: 300,
        centerMode: true,
       slidesToShow: 6,
       slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1600,
          settings: {
            slidesToShow: 5,
            slidesToScroll: 1,
          }
        },
        {
          breakpoint: 1400,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 1,
          }
        },
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 1,
          }
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
          }
        },
        {
        breakpoint: 850,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
        // You can unslick at a given breakpoint now by adding:
        // settings: "unslick"
        // instead of a settings object
      ]
     });
 });


})(jQuery); // Fully reference jQuery after this point.
